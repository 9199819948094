import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyles } from "@constants/theme";
import { AuthenticationProvider } from "@context/AuthenticationContext";
import Head from "next/head";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { TrackPage } from "@util/analytics";

function App({ Component, pageProps }) {
  useEffect(() => {
    // track display name for segment analytics
    TrackPage(Component.displayName);
  }, [Component]);

  useEffect(() => {
    // load logrocket on app init
    if (
      typeof window !== "undefined" &&
      process.env.NEXT_PUBLIC_LOGROCKET_IS_ENABLED === "true"
    ) {
      LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_KEY);
      setupLogRocketReact(LogRocket);
    }
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <ThemeProvider theme={theme}>
      <AuthenticationProvider>
        <GlobalStyles />
        <React.Fragment>
          {getLayout(<Component {...pageProps} />)}
        </React.Fragment>
      </AuthenticationProvider>
    </ThemeProvider>
  );
}

export default App;
