import LogRocket from "logrocket";

const isSegmentEnabled = process.env.NEXT_PUBLIC_SEGMENT_IS_ENABLED === "true";
const isLogRocketEnabled =
  process.env.NEXT_PUBLIC_LOGROCKET_IS_ENABLED === "true";

// initial identify data set site wide
export const Identify = (user) => {
  if (user) {
    const isRealworldEmployee = user.email?.includes("@realworld.co");

    if (isLogRocketEnabled) {
      LogRocket.identify(user.id, {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
      });
    }

    if (typeof window !== "undefined") {
      if (
        process.env.NEXT_PUBLIC_NODE_ENV === "production" &&
        isRealworldEmployee
      ) {
        window.localStorage.setItem("isRealworldEmployee", "true");
      }
    }

    if (isSegmentEnabled && !isRealworldEmployee) {
      if (
        typeof window !== "undefined" &&
        window.analytics &&
        window.analytics.identify
      ) {
        window.analytics.identify(user.id);
      }
    }
  }
};

// identify data removed on logout
export const Reset = () => {
  if (isSegmentEnabled) {
    if (
      typeof window !== "undefined" &&
      window.analytics &&
      window.analytics.reset
    ) {
      window.analytics.reset();
    }
  }

  if (typeof window !== "undefined") {
    // clear local storage
    window.localStorage.clear();
  }
};

// individual actions tracking
export const Track = (event, properties = {}) => {
  let isRealworldEmployee = false;

  if (typeof window !== "undefined") {
    isRealworldEmployee = window.localStorage.getItem("isRealworldEmployee")
      ? true
      : false;
  }

  if (
    isSegmentEnabled &&
    !isRealworldEmployee &&
    typeof window !== "undefined" &&
    window.analytics &&
    window.analytics.track
  ) {
    const options = {
      ...properties,
      platform: "web",
    };
    window.analytics.track(event, options);
  }
};

// individual actions tracking
export const TrackPage = (displayName) => {
  let isRealworldEmployee = false;

  if (typeof window !== "undefined") {
    isRealworldEmployee = window.localStorage.getItem("isRealworldEmployee")
      ? true
      : false;
  }

  if (
    isSegmentEnabled &&
    !isRealworldEmployee &&
    typeof window !== "undefined" &&
    window.analytics &&
    window.analytics.page
  ) {
    window.analytics.page(displayName);
  }
};
