import { createGlobalStyle } from "styled-components";
import localFont from "@next/font/local";

// using @next/fonts to avoid font loading "flash"
// https://nextjs.org/docs/basic-features/font-optimization
const GTAmericaRegular = localFont({
  src: [
    {
      path: "../assets/fonts/GT-America-Regular.woff",
      style: "normal",
      weight: "400",
    },
  ],
  fallback: ["arial", "sans-serif"],
});

const GTAmericaMedium = localFont({
  src: [
    {
      path: "../assets/fonts/GT-America-Standard-Medium.woff",
      style: "normal",
      weight: "500",
    },
  ],
  fallback: ["arial", "sans-serif"],
});

const GTAmericaRegularItalic = localFont({
  src: [
    {
      path: "../assets/fonts/GT-America-Standard-Regular-Italic.woff",
      style: "normal",
      weight: "400",
    },
  ],
  fallback: ["arial", "sans-serif"],
});

const GTAmericaBold = localFont({
  src: [
    {
      path: "../assets/fonts/GT-America-Bold.woff",
      style: "normal",
      weight: "700",
    },
  ],
  fallback: ["arial", "sans-serif"],
});

const GTAmericaCompressedBlack = localFont({
  src: [
    {
      path: "../assets/fonts/GT-America-Compressed-Black.woff",
      style: "normal",
      weight: "900",
    },
  ],
  fallback: ["arial", "sans-serif"],
});

const GTAmericaExtendedBold = localFont({
  src: [
    {
      path: "../assets/fonts/GT-America-Extended-Bold.woff",
      style: "normal",
      weight: "700",
    },
  ],
  fallback: ["arial", "sans-serif"],
});

const RecoletaMedium = localFont({
  src: [
    {
      path: "../assets/fonts/Recoleta-Medium.woff",
      style: "normal",
      weight: "500",
    },
  ],
  fallback: ["Georgia", "serif"],
});

const RecoletaBold = localFont({
  src: [
    {
      path: "../assets/fonts/Recoleta-Bold.woff",
      style: "normal",
      weight: "700",
    },
  ],
  fallback: ["Georgia", "serif"],
});

const RecoletaSemiBold = localFont({
  src: [
    {
      path: "../assets/fonts/Recoleta-SemiBold.woff",
      style: "normal",
      weight: "600",
    },
  ],
  fallback: ["Georgia", "serif"],
});

export const size = {
  mobileS: "320",
  mobileM: "375",
  mobileL: "425",
  mobileXL: "600",
  tablet: "768",
  tabletL: "850",
  laptop: "1024",
  laptopM: "1280",
  laptopL: "1440",
  desktop: "2560",
};

const formatFont = (font, fontName) => {
  if (font) {
    if (font.style) {
      if (font.style.fontFamily) {
        return `${fontName}_Local, ${font.style.fontFamily}, ${fontName}`;
      }
    }
  }

  return `${fontName}_Local, ${fontName}`;
};

export const fonts = {
  GTAmericaRegular: formatFont(GTAmericaRegular, "GTAmericaRegular"),
  GTAmericaMedium: formatFont(GTAmericaMedium, "GTAmericaMedium"),
  GTAmericaRegularItalic: formatFont(
    GTAmericaRegularItalic,
    "GTAmericaRegularItalic"
  ),
  GTAmericaBold: formatFont(GTAmericaBold, "GTAmericaBold"),
  GTAmericaCompressedBlack: formatFont(
    GTAmericaCompressedBlack,
    "GTAmericaCompressedBlack"
  ),
  GTAmericaExtendedBold: formatFont(
    GTAmericaExtendedBold,
    "GTAmericaExtendedBold"
  ),
  RecoletaMedium: formatFont(RecoletaMedium, "RecoletaMedium"),
  RecoletaBold: formatFont(RecoletaBold, "RecoletaBold"),
  RecoletaSemiBold: formatFont(RecoletaSemiBold, "RecoletaSemiBold"),
};

export const theme = {
  fonts,
  device: {
    mobileXS: `(max-width: ${size.mobileS}px)`,
    mobileS: `(min-width: ${size.mobileS}px)`,
    mobileM: `(min-width: ${size.mobileM}px)`,
    mobileL: `(min-width: ${size.mobileL}px)`,
    mobileXL: `(min-width: ${size.mobileXL}px)`,
    tablet: `(min-width: ${size.tablet}px)`,
    tabletL: `(min-width: ${size.tabletL}px)`,
    laptop: `(min-width: ${size.laptop}px)`,
    laptopM: `(min-width: ${size.laptopM}px)`,
    laptopL: `(min-width: ${size.laptopL}px)`,
    desktop: `(min-width: ${size.desktop}px)`,
    desktopL: `(min-width: ${size.desktop}px)`,
  },
  // ! note:  this is all based on the design system which is only for mobile right now, so typography may change!
  typography: {
    billboard1: `
      font-family: ${fonts.GTAmericaCompressedBlack};
      font-size: 80px;
      line-height: 80px;
      text-transform: uppercase;
      font-weight: 900;
    `,
    billboard2: `
      font-family: ${fonts.GTAmericaCompressedBlack};
      font-size: 32px;
      line-height: 32px;
      text-transform: uppercase;
      font-weight: 900;
    `,
    button: `
      font-family: ${fonts.GTAmericaMedium};
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;
    `,
    buttonLite: `
      font-family: ${fonts.GTAmericaRegular};
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 400;
    `,
    buttonSmall: `
      font-family: ${fonts.GTAmericaMedium};
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;
    `,
    caption1: `
      font-family: ${fonts.GTAmericaRegular};
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    `,
    caption2: `
      font-family: ${fonts.GTAmericaRegular};
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    `,
    // Not in the design system, we may want to come up with a different solution
    caption1Bold: `
      font-family: ${fonts.GTAmericaBold};
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
    `,
    h1: `
      font-family: ${fonts.RecoletaBold};
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.5px;
      font-weight: 700;
      `,
    h2: `
      font-family: ${fonts.RecoletaBold};
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      `,
    h3: `
      font-family: ${fonts.RecoletaSemiBold};
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    `,
    h4: `
      font-family: ${fonts.RecoletaMedium};
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      `,
    h5: `
      font-family: ${fonts.GTAmericaMedium};
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      `,
    h6: `
      font-family: ${fonts.GTAmericaBold};
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      `,
    label: `
      font-family: ${fonts.GTAmericaRegular};
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 400;
      `,
    link: `
      font-family: ${fonts.GTAmericaMedium};
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 500;
      `,
    p1: `
      font-family: ${fonts.GTAmericaRegular};
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      `,
    p2: `
      font-family: ${fonts.GTAmericaRegular};
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      `,
    italic: `
      font-family: ${fonts.GTAmericaRegularItalic};
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    `,
  },
  colors: {
    action: {
      strawberry: "#ED4956",
    },
    brand: {
      primary: "#0894B2", // teal.dark
      secondary: "#14B7DA", // teal.medium_dark
      accent_primary: "#A3DEEB", // teal.medium
      accent_secondary: "#F0FCFF", // teal.light
    },
    grayscale: {
      white: "#FFFFFF",
      charcoal: "#2B2B2F",
      steel: "#636D75",
      smoke: "#7F888F",
      graphite: "#8BA5BB",
      ash: "#A0ACB5",
      dust: "#E2E5EB",
      pearl: "#F8F8F8",
      stone: "#C6CBCF",
      fog: "#F2F2F2",
    },
    playbooks: {
      red: {
        dark: "#F05136",
        medium: "#FFD1CE",
        medium_dark: "#FF593D",
        light: "#FFF2F0",
      },
      orange: {
        dark: "#E06F07",
        medium: "#FBDAC2",
        medium_dark: "#FF9A3D",
        light: "#FFF4EB",
      },
      yellow: {
        dark: "#FEBB11",
        medium: "#FFEAB5",
        medium_dark: "#FDCB4D",
        light: "#FFFCE2",
      },
      green: {
        dark: "#09A463",
        medium: "#AFEDC0",
        medium_dark: "#0CDA84",
        light: "#F1FFF9",
      },
      teal: {
        dark: "#0894B2",
        medium: "#A3DEEB",
        medium_dark: "#14B7DA",
        light: "#F0FCFF",
      },
      blue: {
        dark: "#3654F0",
        medium: "#CED9FF",
        medium_dark: "#4865FF",
        light: "#F1F5FF",
      },
      purple: {
        dark: "#9336F0",
        medium: "#E4D1F8",
        medium_dark: "#A449FF",
        light: "#F7F4FF",
      },
      pink: {
        dark: "#DF46A2",
        medium: "#FFDBF0",
        medium_dark: "#FF55BB",
        light: "#FEF5FF",
      },
    },
  },
};

export const GlobalStyles = createGlobalStyle`
  html,
  body,
  body > div:first-child,
  div#__next,
  div#__next > div {
    height: 100%;
  }

  body {
    margin: 0px;
    font-family: ${fonts.GTAmericaRegular};
    background-color: ${theme.colors.grayscale.white};
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Custom Scrollbar */
  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.grayscale.ash} ${theme.colors.grayscale.white};
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: ${theme.colors.grayscale.white};
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.grayscale.ash};
    border-radius: 20px;
    border: 3px solid ${theme.colors.grayscale.white};
  }

  // class used to forcibly hide the nav bar
  // typically used when an overlay is open - as needed - for example: LibraryPlaybookMenu
  .force-hide-nav-bar {
    top: -90px !important;
  }

  /*
    the following font definitions are helpful for fast font load times
    and are also needed for fonts to work in cypress.
    If your fonts are still not displaying correctly within cypress -
    then check that your local computer font files are named correctly
  */
  @font-face {
    font-family: 'GTAmericaRegular_Local';
    src: local("GTAmerica-Regular");
  }
  @font-face {
    font-family: 'GTAmericaMedium_Local';
    src: local("GTAmerica-Md");
  }
  @font-face {
    font-family: 'GTAmericaRegularItalic_Local';
    src: local("GTAmerica-Mdlt");
  }
  @font-face {
    font-family: 'GTAmericaBold_Local';
    src: local("GTAmerica-Bold");
  }
  @font-face {
    font-family: 'GTAmericaCompressedBlack_Local';
    src: local("GTAmerica-CompressedBlack");
  }

  @font-face {
    font-family: 'RecoletaMedium_Local';
    src: local("Recoleta-Medium");
  }
  @font-face {
    font-family: 'RecoletaBold_Local';
    src: local("Recoleta-Bold");
  }
  @font-face {
    font-family: 'RecoletaSemiBold_Local';
    src: local("Recoleta-SemiBold");
  }
`;
