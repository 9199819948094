import { createContext, useState } from "react";
import { useRouter } from "next/router";
import DjangoServer from "@util/DjangoServer";
import { Identify, Reset } from "@util/analytics";

const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const router = useRouter();

  const login = async ({ email, password }) => {
    const response = await DjangoServer.post("/api/login/", {
      email,
      password,
    });
    setUser({ ...response.data });

    // send analytic data & set segment identity
    Identify(response.data);
  };

  const register = async ({
    first_name,
    last_name,
    date_of_birth,
    email,
    password,
    password_confirmation,
    accepted_terms,
  }) => {
    const response = await DjangoServer.post("/api/users/", {
      first_name,
      last_name,
      date_of_birth,
      email,
      password,
      password_confirmation,
      accepted_terms,
      web: true,
    });
    setUser({ ...response.data });

    // send analytic data & set segment identity
    Identify(response.data);
  };

  const logout = async (redirectTo) => {
    await DjangoServer.post("/api/logout/");
    setUser(null);

    // reset segment identity & remove local storage
    Reset();

    if (redirectTo) {
      router.push(redirectTo);
    }
    return;
  };

  const getUser = async () => {
    const response = await DjangoServer.get("/api/users/user-info/");
    setUser({ ...response.data });
  };

  const deleteUser = async (redirectTo) => {
    await DjangoServer.delete(`/api/users/${user.id}/`);
    setUser(null);

    // reset segment identity & remove local storage
    Reset();

    if (redirectTo) {
      router.push(redirectTo);
    }
  };

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        register,
        login,
        logout,
        getUser,
        deleteUser,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContext;
